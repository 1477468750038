import isDate from "lodash.isdate"
import moment from "moment-timezone"
// import duration from "dayjs/plugin/duration"
// import relativeTime from "dayjs/plugin/relativeTime"

// dayjs.extend(duration)
// dayjs.extend(relativeTime)

const SG_TIMEZONE = "Asia/Singapore";

export function toMoment(datetimeValue) {
  if (isDate(datetimeValue)) {
    return moment(datetimeValue);
  } else {
    // we safeguard against all possible format from backend & possibly frontend
    return moment(datetimeValue, [
      'DD/MM/YYYY',
      'DD/MM/YYYY HH:mm:ss',
      'YYYY-MM-DD',
      'YYYY-MM-DDTHH:mm:ss',
      'MM/DD/YYYY',
      'MM/DD/YYYY HH:mm:ss',
    ]);
  }
}

/**
 * This is to fix issue with input date doesn't revert to local time zone
 * All datetime data should use this method to correct the time zone
 */
export function parseDateTime(datetimeValue, withTime = true) {
  var d = toMoment(datetimeValue)
  if (d.isValid()) {
    return withTime ?
      d.format("YYYY-MM-DD") + "T" + d.format("HH:mm:ss") :
      d.format("YYYY-MM-DD");
  }
  return ""
}

export function parseDateTimeToUtc(datetimeValue, withTime = true) {
  var d = moment.utc(toMoment(datetimeValue))
  if (d.isValid()) {
    return withTime ?
      d.format("YYYY-MM-DD") + "T" + d.format("HH:mm:ss") :
      d.format("YYYY-MM-DD");
  }
  return ""
}

// Display UTC date value. Used for date retrieved from server
export function displayUtcDate(datetimeValue, format="DD/MM/YYYY") {
  var d = moment.utc(datetimeValue)
  return d.isValid() ? d.tz(SG_TIMEZONE).format(format) : '';
}

export function displayUtcDateTime(datetimeValue, format="DD/MM/YYYY HH:mm") {
  var d = moment.utc(datetimeValue)
  return d.isValid() ? d.tz(SG_TIMEZONE).format(format) : '';
}

export function displayUtcTime(datetimeValue, format="HH:mm") {
  var d = moment.utc(datetimeValue)
  return d.isValid() ? d.tz(SG_TIMEZONE).format(format) : '';
}

export function humanizeUtcDate(datetimeValue) {
  var d = moment.utc(datetimeValue)
  return d.isValid() ? moment.duration(d.diff(moment())).humanize(true) : "";
}

// Display date generated by app
export function displayDate(datetimeValue, format="DD/MM/YYYY") {
  var d = toMoment(datetimeValue)
  return d.isValid() ? d.format(format) : '';
}

export function displayDateTime(datetimeValue, format="DD/MM/YYYY HH:mm") {
  var d = toMoment(datetimeValue)
  return d.isValid() ? d.format(format) : '';
}

export function humanizeDate(datetimeValue) {
  var d = toMoment(datetimeValue)
  return d.isValid() ? moment.duration(d.diff(moment())).humanize(true) : "";
}

export function parseDateTimeUTC(datetimeValue, isStartDate) {
  var d = toMoment(datetimeValue);
  if (d.isValid()) {
    var _d = isStartDate ? d.startOf("day") : d.endOf("day");
    const UTC_d = moment.utc(_d);
    return UTC_d.format("YYYY-MM-DD") + "T" + UTC_d.format("HH:mm:ss");
  }
  return "";
}
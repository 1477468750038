const elementId = "AsomLayout__MainContent"

/**
 * @description a Vue plugin to force app to scroll to top
 */
export default {
  install: (app) => {
    app.config.globalProperties.$scrollTo = (x, y) => {
      const elem = document.getElementById(elementId);
      if (elem)
        elem.scrollTo(x, y);
    }

    app.config.globalProperties.$scrollTop = () => {
      const elem = document.getElementById(elementId);
      if (elem)
        elem.scrollTo(0, 0);
    }

    app.config.globalProperties.$scrollTopInstant = () => {
      const elem = document.getElementById(elementId);
      if (elem)
        elem.scrollTo({
          top: 0,
          left: 0,
          behavior: "instant",
        });
    }
  }
}
/**
 * Helper for asom-signature-pad to convert to saveable data 
 */

const b64toblob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (
    let offset = 0;
    offset < byteCharacters.length;
    offset += sliceSize
  ) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export function imageDataToBlob(imageData, fileName) {
  const contentType = "image/png";
  const b64Data = imageData.split(",")[1];
  const blob = b64toblob(b64Data, contentType);
  blob.name = fileName + '.png';
  return blob;
}
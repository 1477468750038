const componentRoutes = [
  {
    path: "/docs",
    name: "List of Components",
    component: () =>
      import(/* webpackChunkName: "docs" */ "./views/DocsIndex.vue"),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/typography",
    name: "Typography",
    component: () =>
      import(
        /* webpackChunkName: "docs_typography" */ "./views/components/Typography.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/icon",
    name: "Icon",
    component: () =>
      import(/* webpackChunkName: "docs_icon" */ "./views/components/Icon.vue"),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/container",
    name: "Container",
    component: () =>
      import(
        /* webpackChunkName: "docs_container" */ "./views/components/Container.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/grid_system",
    name: "GridSystem",
    component: () =>
      import(
        /* webpackChunkName: "docs_grid_system" */ "./views/components/GridSystem.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/button",
    name: "Button",
    component: () =>
      import(
        /* webpackChunkName: "docs_button" */ "./views/components/Button.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/link",
    name: "Link",
    component: () =>
      import(/* webpackChunkName: "docs_link" */ "./views/components/Link.vue"),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/breadcrumb",
    name: "Breadcrumb",
    component: () =>
      import(
        /* webpackChunkName: "docs_breadcrumb" */ "./views/components/Breadcrumb.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/pagination",
    name: "Pagination",
    component: () =>
      import(
        /* webpackChunkName: "docs_pagination" */ "./views/components/Pagination.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/modal",
    name: "Modal",
    component: () =>
      import(
        /* webpackChunkName: "docs_modal" */ "./views/components/Modal.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/card",
    name: "Card",
    component: () =>
      import(/* webpackChunkName: "docs_card" */ "./views/components/Card.vue"),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/alert",
    name: "Alert",
    component: () =>
      import(
        /* webpackChunkName: "docs_alert" */ "./views/components/Alert.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/dropdown",
    name: "Dropdown",
    component: () =>
      import(
        /* webpackChunkName: "docs_dropdown" */ "./views/components/Dropdown.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/tooltip",
    name: "Tooltip",
    component: () =>
      import(
        /* webpackChunkName: "docs_tooltip" */ "./views/components/Tooltip.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/badge",
    name: "Badge",
    component: () =>
      import(
        /* webpackChunkName: "docs_badge" */ "./views/components/Badge.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/spinner",
    name: "Spinner",
    component: () =>
      import(
        /* webpackChunkName: "docs_spinner" */ "./views/components/Spinner.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/input_text",
    name: "Input Text",
    component: () =>
      import(
        /* webpackChunkName: "docs_input_text" */ "./views/components/InputText.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/input_textarea",
    name: "Input Textarea",
    component: () =>
      import(
        /* webpackChunkName: "docs_input_textarea" */ "./views/components/InputTextarea.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/input_checkbox",
    name: "Input Checkbox",
    component: () =>
      import(
        /* webpackChunkName: "docs_input_checkbox" */ "./views/components/InputCheckbox.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/input_radio",
    name: "Input Radio",
    component: () =>
      import(
        /* webpackChunkName: "docs_input_radio" */ "./views/components/InputRadio.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/input_select",
    name: "Input Select",
    component: () =>
      import(
        /* webpackChunkName: "docs_input_select" */ "./views/components/InputSelect.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/input_multi_select",
    name: "Input Multi Select",
    component: () =>
      import(
        /* webpackChunkName: "docs_input_select" */ "./views/InputMultiSelect.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/input_date",
    name: "Input Date",
    component: () =>
      import(
        /* webpackChunkName: "docs_input_date" */ "./views/components/InputDate.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/input_upload",
    name: "Upload",
    component: () =>
      import(
        /* webpackChunkName: "docs_input_upload" */ "./views/components/Upload.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/form_display",
    name: "Form",
    component: () =>
      import(
        /* webpackChunkName: "docs_form_display" */ "./views/components/Form.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/client-table",
    name: "Client Table",
    component: () =>
      import(
        /* webpackChunkName: "docs_client_table" */ "./views/components/ClientTable.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/server-table",
    name: "Server Table",
    component: () =>
      import(
        /* webpackChunkName: "docs_server_table" */ "./views/components/ServerTable.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/navbar",
    name: "Navbar",
    component: () =>
      import(
        /* webpackChunkName: "docs_navbar" */ "./views/components/Navbar.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/slider_menu",
    name: "Slider",
    component: () =>
      import(
        /* webpackChunkName: "docs_schedule" */ "./views/components/Slider.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/bar_code_scanner",
    name: "Bar Code Scanner",
    component: () =>
      import(
        /* webpackChunkName: "docs_schedule" */ "./views/components/BarCodeScanner.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/qr_code_scanner",
    name: "QR Code Scanner",
    component: () =>
      import(
        /* webpackChunkName: "docs_schedule" */ "./views/components/QRCodeScanner.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/signature_pad",
    name: "Signature Pad",
    component: () =>
      import(
        /* webpackChunkName: "docs_signature_pad" */ "./views/components/SignaturePad.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/human_diagram",
    name: "Human Diagram Input",
    component: () =>
      import(
        /* webpackChunkName: "docs_human_diagram" */ "./views/components/HumanDiagram.vue"
      ),
    meta: { allowsAnonymous: true },
  },
  {
    path: "/docs/input_multi_email",
    name: "Input Multi Email",
    component: () =>
      import(
        /* webpackChunkName: "docs_input_multi_email" */ "./views/components/InputMultiEmail.vue"
      ),
    meta: { allowsAnonymous: true },
  },
];

// const templateRoutes = [
//   {
//     path: "/docs/design/example",
//     name: "Example",
//     component: () => import("./views/design/Example.vue"),
//     meta: { allowsAnonymous: true },
//   },

//   {
//     path: "/docs/design/new_dashboard_layout",
//     name: "New Dashboard Layout",
//     component: () => import("./views/design/NewDashboardLayout.vue"),
//     meta: { allowsAnonymous: true },
//   },
//   {
//     path: "/docs/design/left_nav_second_level",
//     name: "Left Nav Second Level",
//     component: () => import("./views/design/LeftNavSecondLevel.vue"),
//     meta: { allowsAnonymous: true },
//   },
//   {
//     path: "/docs/design/roster_list_view",
//     name: "Roster List View",
//     component: () => import("./views/design/RosterListView.vue"),
//     meta: { allowsAnonymous: true },
//   },
//   {
//     path: "/docs/design/roster_list_view_personal_cycle",
//     name: "Roster List View Personal - Cycle",
//     component: () => import("./views/design/RosterListViewPersonalCycle.vue"),
//     meta: { allowsAnonymous: true },
//   },
//   {
//     path: "/docs/design/roster_list_view_personal_week",
//     name: "Roster List View Personal - Week",
//     component: () => import("./views/design/RosterListViewPersonalWeek.vue"),
//     meta: { allowsAnonymous: true },
//   },
//   {
//     path: "/docs/design/new_login_screen",
//     name: "New Login Screen",
//     component: () => import("./views/design/NewLoginScreen.vue"),
//     meta: { allowsAnonymous: true },
//   },
//   {
//     path: "/docs/schedule",
//     name: "Schedule",
//     component: () =>
//       import(/* webpackChunkName: "docs_schedule" */ "./views/Schedule.vue"),
//     meta: { allowsAnonymous: true },
//   },
//   {
//     path: "/docs/design/station_cash_management_overview",
//     name: "Station Cash Mnagement Overview",
//     component: () => import("./views/design/stationcashmanagementoverview.vue"),
//     meta: { allowsAnonymous: true },
//   },
//   {
//     path: "/docs/design/psc",
//     name: "PSC",
//     component: () => import("./views/design/psc.vue"),
//     meta: { allowsAnonymous: true },
//   },
// ];

// const integrateTemplateRoutes = [
//   {
//     path: "/docs/integrate_design/layout",
//     name: "Layout 001",
//     component: () => import("./views/integrate_design/Layout.vue"),
//     meta: { allowsAnonymous: true },
//   },
// ];

// const docsRoute = process.env.NODE_ENV === 'production' ?  [] : [
const docsRoute = [
  ...componentRoutes,
  // ...templateRoutes,
  // ...integrateTemplateRoutes,
];

export default docsRoute;

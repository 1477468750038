import get from 'lodash.get'

/**
 * @param route https://next.router.vuejs.org/api/#matched
 * @param i18n https://kazupon.github.io/vue-i18n/api/#vue-injected-methods
 * @description extract breadcrumb data from route info
 * Look up to meta of each route in path and apply 
 */
export default (route, i18n) => {
  let breadcrumbs = [
    { text: 'Home', 'href': '#' },
  ];
  const { matched = [] } = route;
  matched.forEach(({ meta, path }) => {
    let href = `#${path}`
    if (!breadcrumbs.some(item => item.href === href)) {
      breadcrumbs.push({
        text: i18n(get(meta, 'title', '')),
        href
      });
    }
    
  })

  return breadcrumbs;
}
import ExportJsonExcel from "js-export-excel";

export const exportExcel = (
	fileName = "Excel file",
	sheetName,
	sheetFilter,
	sheetHeader,
	sheetData
) => {
	// tableInstance.api.exportDataAsCsv({});
	var option = {};

	option.fileName = fileName;
	option.datas = [
		{
			sheetData,
			sheetName,
			sheetFilter,
			sheetHeader,
			// columnWidths: [20, 20],
		},
	];

	if(fileName === 'Cash Order Report'){
		var dateHeaderWidth = Array.from({
			length: sheetHeader.length - 3
		}, () => 5)

		option.datas[0].columnWidths = [3, 10, ...dateHeaderWidth]
	}

	var toExcel = new ExportJsonExcel(option);
	toExcel.saveExcel();
};

export const dateTrim = (date) => {
    return date.slice(0, -3);
}
import get from "lodash.get";

const NOTIFICATION_CATEGORY = {
  Manpower_SAPConflict: {
    enum: 101,
    route: "/manpower",
    requireTarget: false,
  },
  Manpower_NominatedForReplacement: {
    enum: 102,
    route: "/manpower",
    requireTarget: false,
  },
  Manpower_RosterUnavailable: {
    enum: 103,
    route: "/manpower",
    requireTarget: false,
  },
  Manpower_MutualExchange: {
    enum: 104,
    route: "/manpower",
    requireTarget: false,
  },
  Manpower_ShiftExtension: {
    enum: 105,
    route: "/manpower",
    requireTarget: false,
  },
  Manpower_PlannedOT: {
    enum: 106,
    route: "/manpower",
    requireTarget: false,
  },
  Manpower_EditedRoster: {
    enum: 107,
    route: "/manpower",
    requireTarget: false,
  },
  Manpower_SAPError: {
    enum: 108,
    route: "/manpower",
    requireTarget: false,
  },
  Manpower_SAPConflict_Broadcast: {
    enum: 109,
    route: "/manpower",
    requireTarget: false,
  },
  Manpower_Replacement_Broadcast: {
    enum: 110,
    route: "/manpower",
    requireTarget: false,
  },
  Manpower_MarkUnavailable_Broadcast: {
    enum: 111,
    route: "/manpower",
    requireTarget: false,
  },
  Manpower_MutualExchange_Broadcast: {
    enum: 112,
    route: "/manpower",
    requireTarget: false,
  },
  Manpower_ShiftExtension_Broadcast: {
    enum: 113,
    route: "/manpower",
    requireTarget: false,
  },
  Manpower_PlannedOT_Broadcast: {
    enum: 114,
    route: "/manpower/planned_ot",
    requireTarget: true,
  },
  Manpower_EditedRoster_Broadcast: {
    enum: 115,
    route: "/manpower",
    requireTarget: false,
  },
  Manpower_MaxConsecutiveDays: {
    enum: 116,
    route: "/manpower",
    requireTarget: false,
  },
  Manpower_MaxOtHours: {
    enum: 117,
    route: "/manpower",
    requireTarget: false,
  },
  // Cash_PSMHandover: {
  //   enum: 201,
  //   route: '/cash-mgnt',
  // },
  Cash_DiscrepancyReport: {
    enum: 202,
    route: "/cash-mgnt/discrepancy-reports",
    requireTarget: false,
  },
  Cash_CashBalance: {
    enum: 203,
    route: "/cash-mgnt",
    requireTarget: false,
  },
  Cash_CashOrderForApproval: {
    enum: 204,
    route: "/cash-mgnt/cash-order",
    requireTarget: false,
  },
  Cash_CashOrderDeliveryFulfilled: {
    enum: 206,
    route: "/cash-mgnt/cash-order",
    requireTarget: false,
  },
  Cash_CashOrderStationTransfered: {
    enum: 207,
    route: "/cash-mgnt/cash-order",
    requireTarget: false,
  },
  // Cash_UserNotOnShift: {
  //   enum: 208,
  //   route: '/cash-mgnt',
  // },
  Cash_CDFToWitness: {
    enum: 209,
    route: "/cash-mgnt/cash-declaration-form/witness-sign",
    requireTarget: true,
  },
  Cash_CDFToBagger: {
    enum: 210,
    route: "/cash-mgnt/cash-declaration-form/view",
    requireTarget: true,
  },
  Cash_WitnessMovingBagToSafe: {
    enum: 211,
    route: "/cash-mgnt/afc/cashbag",
    requireTarget: false,
  },
  Cash_StationTransferApproved: {
    enum: 213,
    route: "/cash-mgnt/cash-order",
    requireTarget: false,
  },

  Inventory_StockBelowThreshold: {
    enum: 301,
    route: "/inventory",
    requireTarget: false,
  },
  Inventory_StockNearExpirationDate: {
    enum: 302,
    route: "/inventory",
    requireTarget: false,
  },
  Inventory_StockExpired: {
    enum: 303,
    route: "/inventory",
    requireTarget: false,
  },
  Inventory_OutOfStock: {
    enum: 304,
    route: "/inventory",
    requireTarget: false,
  },
  Inventory_PlaceOrder: {
    enum: 305,
    route: "/inventory/order",
    requireTarget: false,
  },
  Inventory_ApproveOrder: {
    enum: 306,
    route: "/inventory/order",
    requireTarget: false,
  },
  Inventory_RejectOrder: {
    enum: 308,
    route: "/inventory/order",
    requireTarget: false,
  },

  EquipmentFault_OccurrenceNearExpiry: {
    enum: 401,
    route: "/fault-mgnt/station-occurrence",
    requireTarget: false,
  },
  EquipmentFault_OccurenceExpired: {
    enum: 402,
    route: "/fault-mgnt/station-occurrence",
    requireTarget: false,
  },
  EquipmentFault_FaultUpdated: {
    enum: 403,
    route: "/fault-mgnt/station-fault",
    requireTarget: false,
  },

  OpsComm__NewOpsComm: {
    enum: 501,
    route: "/acknowledgements/view",
    requireTarget: true,
  },
  OpsComm__OpsCommNearDeadline: {
    enum: 502,
    route: "/acknowledgements/view",
    requireTarget: true,
  },
  OpsComm__OpsCommPassDeadline: {
    enum: 503,
    route: "/acknowledgements/view",
    requireTarget: true,
  },
  OpsComm_DeadlineDate: {
    enum: 504,
    route: "/acknowledgements/view",
    requireTarget: true,
  },
  OpsComm_NearDeadlineDate: {
    enum: 505,
    route: "/acknowledgements/view",
    requireTarget: true,
  },

  ShiftHandover_HandoverCompleted: {
    enum: 601,
    route: "/shift-handover/details",
    requireTarget: true,
  },
  ShiftHandover_Takeover: {
    enum: 602,
    route: "/shift-handover/takeover",
    requireTarget: true,
  },
  ShiftHandover_Rejection: {
    enum: 603,
    route: "/shift-handover",
    requireTarget: false,
  },
  ShiftHandover_Submission: {
    enum: 604,
    route: "/shift-handover",
    requireTarget: false,
  },
};

export default function ({ notificationCategoryEnum, notificationTargetId }) {
  var matchCategory = null;
  Object.keys(NOTIFICATION_CATEGORY).forEach((key) => {
    if (
      get(NOTIFICATION_CATEGORY, `${key}.enum`) === notificationCategoryEnum
    ) {
      matchCategory = NOTIFICATION_CATEGORY[key];
    }
  });

  if (!!matchCategory && matchCategory.enum) {
    if (matchCategory.requireTarget) {
      return `${matchCategory.route}/${notificationTargetId}`;
    } else {
      return matchCategory.route;
    }
  }
  return "/error";
}


//获取值
export function getStorage(key) {
    const value = sessionStorage.getItem(key);
    return value && value !== 'undefined' ? JSON.parse(value) : null;
}

//存储值
export function setStorage(key, value) {
    // 存储当前字段,清除其他储存字段
    const searchList = sessionStorage.getItem('searchList');
    if (searchList && searchList !== 'undefined') {
        const searchListObj = JSON.parse(searchList) || [];
        if (!searchList.includes(key)) searchListObj.push(key);
        const searchLi = Array.from(new Set(searchListObj));
        searchLi.forEach(item => {
            if (item !== key) sessionStorage.removeItem(item);
        });
        sessionStorage.setItem('searchList', JSON.stringify(searchLi));
    } else {
        sessionStorage.setItem('searchList', JSON.stringify([key]));
    }

    sessionStorage.setItem(key, JSON.stringify(value));
}

//清除所有值
export function clearStorage() {
    sessionStorage.clear();
}

import CryptoJS from "crypto-js";
import he from 'he';

export function encrypt(secret, publicKey) {
  return CryptoJS.AES.encrypt(secret, publicKey).toString();
}

/**
 * This is specifically to bypass issue with Azure WAF rules for request body
 */
export function encodePostRequestBodyContent(content) {
  return he.encode(content, { encodeEverything: true });
}
export function decodePostRequestBodyContent(content) {
  return he.decode(content);
}
import numeral from "numeral";

export function multiply(value1, value2) {
  if (typeof value1 == "number" && typeof value2 == "number") {
    const numeralValue1 = numeral(value1);
    const numeralValue2 = numeral(value2);
    return Number.isNaN(numeralValue1.value()) ||
      Number.isNaN(numeralValue2.value())
      ? 0
      : numeralValue1.multiply(numeralValue2.value()).value();
  } else return NaN;
}

export function add(value1, value2) {
  if (typeof value1 == "number" && typeof value2 == "number") {
    const numeralValue1 = numeral(value1);
    const numeralValue2 = numeral(value2);
    return Number.isNaN(numeralValue1.value()) ||
      Number.isNaN(numeralValue2.value())
      ? 0
      : numeralValue1.add(numeralValue2.value()).value();
  } else return NaN;
}

export function subtract(value1, value2) {
  if (typeof value1 == "number" && typeof value2 == "number") {
    const numeralValue1 = numeral(value1);
    const numeralValue2 = numeral(value2);
    return Number.isNaN(numeralValue1.value()) ||
      Number.isNaN(numeralValue2.value())
      ? 0
      : numeralValue1.subtract(numeralValue2.value()).value();
  } else return NaN;
}

export function divide(value1, value2) {
  if (typeof value1 == "number" && typeof value2 == "number") {
    const numeralValue1 = numeral(value1);
    const numeralValue2 = numeral(value2);
    return Number.isNaN(numeralValue1.value()) ||
      Number.isNaN(numeralValue2.value())
      ? 0
      : numeralValue1.divide(numeralValue2.value()).value();
  } else return NaN;
}

export function formatCurrency(value) {
  if (typeof value == "number") {
    const numeralValue = numeral(value);
    return Number.isNaN(numeralValue.value())
      ? "$0"
      : numeralValue.format("$0,0.00");
  } else return "N.A";
}
